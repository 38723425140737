import React, { useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"

import { SlideGal } from "../../../components/elements"

import comm_1 from "../../../images/capacitacion/Cap1.jpg"
import comm_2 from "../../../images/community/comm_2.png"
import comm_3 from "../../../images/capacitacion/Cap3.png"
import graphic from "../../../images/graphics/texture-left.png"

import "../../../styles/ourcomunidad.css"

function Community() {
  const [slideMenu, setSlideMenu] = useState(false)
  const toggleSlider = () => {
    setSlideMenu(!slideMenu)
  }

  return (
    <Container className="custom_margin">
      <img src={graphic} alt="" className="imgmov img-fluid" />
      <Row className="">
        <Col>
          <h2 className="orange_text font-weight-bold">Nuestra comunidad</h2>

          <h5 className="font-weight-bold">
            Ellos son parte de nuestra comunidad y de nuestros casos de éxito
          </h5>
        </Col>
      </Row>
      <Row className="d-flex parent justify-content-space-around">
        <Col className="" sm={12} md={4} lg={4}>
          <img className="img_modifier" alt={"img_gallery_1"} src={comm_3} />
          <div className="overlay_succ1">
            <p className="text_succ1">
              <b>Caso de éxito</b> <br />
              Hola, me llamo Lourdes.
              <br />
              En Junio 2017 empecé a trabajar en la panadería la Baguette. Estoy
              muy contenta, puedo ayudar a mi mamá y ahorrar para comprarme mis
              cosas.
            </p>
          </div>
        </Col>

        <Col className="colimgrowmargin" sm={12} md={4} lg={4}>
          <img className="img_modifier" alt={"img_gallery_2"} src={comm_1} />
          <div className="overlay_succ2">
            <p className="text_succ2">
              <b>Caso de éxito</b>
              <br />
              Yo me llamo Viani y en Agosto de 2019 entré a trabajar a Pasteles
              Caseros, siempre me gusto hacer postres y me siento muy contenta
              de estar aquí. <br />
              Le digo gracias a mis maestras y a mis jefas por mi trabajo.
            </p>
          </div>
        </Col>

        <Col className="" sm={12} md={4} lg={4}>
          <img className="img_modifier" alt={"img_gallery_2"} src={comm_2} />
          <div className="overlay_community">
            <p className="text_community">
              <b>Nuestra comunidad</b>
              <Button onClick={toggleSlider} className="button_comm">
                Ver más imagenes
              </Button>
            </p>
            <SlideGal toggle={toggleSlider} slideMenu={slideMenu} />
          </div>
          <div className="d-flex justify-content-center align-items-baseline"></div>
        </Col>
      </Row>
    </Container>
  )
}

export default Community
