import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ContactForm({ isFetching, startPostContact }) {
  const [formVals, setFormVals] = useState({
    fullName: "",
    email: "",
    reason: "",
    message: "",
  })
  const getValue = ({ target: { name, value } }) => {
    setFormVals({
      ...formVals,
      [name]: value,
    })
  }
  const handleSubmit = e => {
    e.preventDefault()
    const data = { ...formVals }
    console.log({ data })
    const formData = new FormData()
    for (let key in data) {
      data[key] && formData.append(key, data[key])
    }
    console.log({ formData })
    startPostContact(formData)
  }
  const { fullName, email, reason, message } = formVals
  return (
    <>
      {isFetching ? (
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <FontAwesomeIcon
                size="3x"
                className="text-white"
                spin
                icon="spinner"
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Form onSubmit={handleSubmit} className="ml-5 ">
          <FormGroup row>
            <Label for="fullName" sm={2}>
              Nombre
            </Label>
            <Col sm={10}>
              <Input
                value={fullName}
                onChange={getValue}
                required
                type="text"
                name="fullName"
                id="fullName"
                placeholder="nombre completo"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={2}>
              Email
            </Label>
            <Col sm={10}>
              <Input
                value={email}
                onChange={getValue}
                required
                type="email"
                name="email"
                id="email"
                placeholder="usuario@correo.com"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="reason" sm={2}>
              Opciones
            </Label>
            <Col sm={10}>
              <Input
                value={reason}
                onChange={getValue}
                type="select"
                name="reason"
                id="reason"
              >
                <option>Selecciona una opción</option>
                <option value="pedidos">Pedidos</option>
                <option value="información">Información</option>
                <option value="información">Otros</option>
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="message" sm={2}>
              Mensaje
            </Label>
            <Col sm={10}>
              <Input
                value={message}
                onChange={getValue}
                required
                type="textarea"
                name="message"
                id="message"
              />
            </Col>
          </FormGroup>
          <FormGroup check row>
            <Col className="px-0" sm={{ size: 10, offset: 2 }}>
              <Button type="submit" className="mt-0 butn_blue_eco">
                Enviar
              </Button>
            </Col>
          </FormGroup>
        </Form>
      )}
    </>
  )
}

export default ContactForm
