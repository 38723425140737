import React from "react"
import { Provider } from "react-redux"
import { store } from "../redux/store"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Home, Video, Somos, Educacion } from "../components/sections"
import {
  Community,
  Donations,
  LocalEco,
  ContactMap,
} from "../components/sections/landing"

const IndexPage = () => (
  <Provider store={store()}>
    <Layout>
      <SEO title="Home" />
      <Home />
      <div id="start" />
      <Video />
      <Somos />
      <Educacion />
      <Community />
      <div id="Dona" />
      <Donations />
      <LocalEco />
      <div id="Contact" />
      <ContactMap />
    </Layout>
  </Provider>
)

export default IndexPage
