import { takeLatest, put, call } from "redux-saga/effects"
import * as actions from "../actions/contact-actions"
import { api } from "../../services"

function* startPostContact({ dataContact }) {
  yield put(actions.reset_contact())
  yield put(actions.request_contact())
  try {
    const respCont = yield call(api.postContact, dataContact)
    console.log("respCont", respCont)
    if (respCont.error) {
      yield put(actions.error_contact(respCont.error))
      yield put(actions.cancel_contact())
    } else {
      yield put(actions.success_contact(respCont.message))
      yield put(actions.cancel_contact())
    }
  } catch (error) {
    yield put(actions.error_contact(error))
    yield put(actions.cancel_contact())
  }
}

export function* watchContact() {
  yield takeLatest(actions.START_POST_CONTACT, startPostContact)
}
