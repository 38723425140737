export const START_POST_CONTACT = "START_POST_CONTACT"
export const REQUEST_CONTACT = "REQUEST_CONTACT"
export const CANCEL_CONTACT = "CANCEL_CONTACT"
export const SUCCESS_CONTACT = "SUCCESS_CONTACT"
export const ERROR_CONTACT = "ERROR_CONTACT"
export const RESET_CONTACT = "RESET_CONTACT"

export const start_post_contact = dataContact => ({
  type: START_POST_CONTACT,
  dataContact,
})

export const request_contact = () => ({
  type: REQUEST_CONTACT,
  isFetching: true,
})

export const cancel_contact = () => ({
  type: CANCEL_CONTACT,
  isFetching: false,
})

export const success_contact = message => ({
  type: SUCCESS_CONTACT,
  message,
})

export const error_contact = error => ({
  type: ERROR_CONTACT,
  error,
})

export const reset_contact = () => ({
  type: RESET_CONTACT,
  message: false,
  error: false,
})
