import { setHFData, fetchStatus, getErrors } from "../utils"
const MAIN_URL = "https://villasjuanpablo.com"

export const api = {
  postContact(contactData) {
    let init
    init = setHFData(init, "POST", contactData)
    console.log(init)
    return fetch(`${MAIN_URL}/php/mail_launch.php`, init)
      .then(fetchStatus)
      .catch(getErrors)
  },
}
