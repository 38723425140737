export const setHJson = (i, method, b) => {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `JWT ${
        sessionStorage.getItem(process.env.REACT_APP_TOKEN_NAME) || ""
      }`,
    }),
  }
  if (b) {
    i.body = JSON.stringify(b)
  }
  return i
}

export const setHFData = (i, method, b) => {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      Accept: "application/json, */*",
      // "Authorization":`JWT ${sessionStorage.getItem('ses_tok')}`
    }),
  }
  if (b) i.body = b
  return i
}

export const fetchStatus = response => {
  if (!response.ok) {
    if (process.env.NODE_ENV === "production") {
      // captureException(response.statusText);
    }
  }
  return response.json() // or return response.text()
}

export const getErrors = error => {
  // captureException(error);
  if (process.env.NODE_ENV === "production") {
    // captureException(error);
  }
  return { error }
}
