import React from "react"
import { Container, Row, Col } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import libreta from "../../../images/products/cuadernos.png"
import lunch from "../../../images/products/lunch.png"
import mermelada from "../../../images/products/marmalade.jpg"

import "../../../styles/community.css"

function LocalEco() {
  return (
    <Container className="">
      <Row className="mt-5 d-flex text-center justify-content-center pl-5 pr-5">
        <h2 className="orange_title mt-5">Ayuda a la economía local</h2>
        <p className="textblackslim">
          Aquí es donde tú entras. Si quieres sentirte parte de la diferencia,
          apóyanos por medio de nuestros productos. Porque en este mundo somos
          muchas las personas que soñamos, organizamos y actuamos juntos.
        </p>
      </Row>
      <Row className="d-flex justify-content-center parent_eco text-center mt-5">
        <Col className="" sm={12} md={4}>
          <img className="img_rowo_eco " alt={"img_gallery_1"} src={libreta} />
          <p className="textblackhard">Libretas</p>
        </Col>
        <Col className="" sm={12} md={4}>
          <img className="img_rowo_eco" alt={"img_gallery_2"} src={lunch} />
          <p className="textblackhard">Lunch</p>
        </Col>
        <Col className="" sm={12} md={4}>
          <img
            className="img_rowo_eco "
            alt={"img_gallery_3"}
            src={mermelada}
          />
          <p className="textblackhard">Mermelada</p>
        </Col>
      </Row>
      <Row className=" mt-5 justify-content-center">
        <h2 className="text-center textblackhard">
          Elige una opción para hacer tu pedido
        </h2>
      </Row>
      <Col
        className="d-flex text-center justify-content-center mt-5"
        sm={12}
        md={12}
      >
        <div className="d-flex text-center justify-content-center">
          <a
            href="https://api.whatsapp.com/send?phone=2223243876&text=Hola,%20quisiera%20hacer%20un%20pedido%20"
            className="butn_blue_eco active mr-5"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              size="2x"
              className="text-light fontaws"
              icon={["fab", "whatsapp"]}
            />
          </a>
        </div>

        <div className="d-flex text-center justify-content-center">
          <a
            href="mailto:contacto@correo.com?Subject=Me gustaria%20hacer%20un%20pedido%20"
            className="ml-2 mr-2 butn_blue_eco active"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              size="2x"
              className="text-light fontaws"
              icon={["fas", "envelope"]}
            />
          </a>
        </div>

        <div className="d-flex text-center justify-content-center">
          <a
            href=" http://m.me/VillasJuanPabloac"
            className="ml-5 butn_blue_eco active"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              size="2x"
              className="text-light fontaws"
              icon={["fab", "facebook-messenger"]}
            />
          </a>
        </div>
      </Col>{" "}
      <br />
      <br />
    </Container>
  )
}

export default LocalEco
