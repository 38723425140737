import React from "react"
import { Container, Row, Col, Button, Card, CardImg } from "reactstrap"
import "../../styles/slidein_gallery.css"

import gall_1 from "../../images/gallery/gall_1.png"
import gall_2 from "../../images/gallery/gall_2.png"
import gall_3 from "../../images/gallery/gall_3.png"
import gall_4 from "../../images/gallery/gall_4.png"
import gall_5 from "../../images/gallery/gall_5.png"
import gall_6 from "../../images/gallery/gall_6.png"
import gall_7 from "../../images/gallery/gall_7.png"
import gall_8 from "../../images/gallery/gall_8.png"
import gall_9 from "../../images/gallery/gall_9.png"

function SlideGal({ slideMenu, toggle, children }) {
  const handleEscape = e => {
    if (slideMenu && e.keyCode === 27) {
      e.preventDefault()
      e.stopPropagation()
      toggle()
    }
  }
  return (
    <Container
      onKeyUp={e => handleEscape(e)}
      className={`slideouts ${slideMenu && "slidein"}`}
    >
      <Row className="mt-5 pt-5 ">
        <Button onClick={() => toggle()} className="buttn_close" close />
      </Row>

      <Row>
        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_1} alt="Card image cap" />
            </Card>
          </div>
        </Col>

        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_2} alt="Card image cap" />
            </Card>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_9} alt="Card image cap" />
            </Card>
          </div>
        </Col>

        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_8} alt="Card image cap" />
            </Card>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_5} alt="Card image cap" />
            </Card>
          </div>
        </Col>

        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_6} alt="Card image cap" />
            </Card>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_7} alt="Card image cap" />
            </Card>
          </div>
        </Col>

        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_3} alt="Card image cap" />
            </Card>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div>
            <Card inverse className="cards_border">
              <CardImg className="img_edit" src={gall_4} alt="Card image cap" />
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SlideGal
