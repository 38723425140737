import React from "react"
import { Container, Row, Col, Button } from "reactstrap"

import "../../styles/slidein_drawer.css"

import portada from "../../images/portadadonacion.png"
/*import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';*/

function SlideMenu({ slideMenu, toggle, children }) {
  const handleEscape = e => {
    if (slideMenu && e.keyCode === 27) {
      e.preventDefault()
      e.stopPropagation()
      toggle()
    }
  }
  return (
    <Container
      onKeyUp={e => handleEscape(e)}
      className={`slideout ${slideMenu && "slidein"}`}
    >
      <Row>
        <Col>
          <img src={portada} alt="" className=" d-flex portada_don" />
        </Col>
      </Row>
      <Row className="bg-blue pt-5 text-center">
        <Col className="d-flex justify-content-start">
          <Button onClick={() => toggle()} close className="clossbtn" />
          <h1 className="slider_header">
            Tu donación se destina a la educación para personas con Síndrome de
            Down.
          </h1>
        </Col>
      </Row>

      <Row className="d-flex text-center">
        <Col>
          <p className="text_white_sm mt-4">
            Haz un depósito a los siguientes números de cuenta
          </p>
        </Col>
      </Row>

      <Row className="ml-3 d-flex text-center">
        <Col>
          <p className="text_white_sm">
            <br />
            <b>Santander</b>
            <br />
            Cuenta: 6550 3155 139 <br />
            Clabe: 014650655031551393
            <br />A nombre de Villas Juan Pablo A.C.
          </p>
          <div>
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="R6KVLVV4WX2G2"
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/es_XC/MX/i/btn/btn_donateCC_LG.gif"
                border="0"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donar con el botón PayPal"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypal.com/es_MX/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          </div>
        </Col>
        <Col className="mt-4 text-white text_white_sm">
          <b> Oxxo Santander </b>
          <br />
          Tarjeta: 5579 0890 0147 5600 <br />
          A nombre de
          <br />
          Lic. Celia Melgarejo Montero <br />
          <br />
          Más informacíon
          <br />
          Teléfono: 222 241 1262 <br />
          <a
            href="https://api.whatsapp.com/send?phone=2223243876&text=Hola,%20quisiera%20más%20informacíon%20"
            target="_blank"
            rel="noopener noreferrer"
            className="text_white_sm pb-0 "
          >
            <u> WhatsApp: 222 324 3876</u> <br />
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default SlideMenu
