import React from "react"
import { Col, Row, Container } from "reactstrap"
import { ContactFormCont } from "../../../containers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "../../../styles/map.css"

function Map() {
  return (
    <Container>
      <Row className="d-flex justify-content-center ">
        <Col className="bg_left" sm={12} md={6}>
          <div className="title_white ml-5 mt-5">Contáctanos</div>
          <div className="ml-5 title_black">Envía un mensaje</div>
          <ContactFormCont />
        </Col>

        <Col className="bg_right " sm={12} md={6}>
          <div className="title_white mt-5">Visítanos en Puebla</div>
          <div className="embed-responsive embed-responsive-16by9 mt-5">
            <iframe
              className="embed-responsive-item"
              title="Villas Juan Pablo"
              width="600"
              height="450"
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.879973779071!2d-98.26846215015804!3d18.98090748707943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfb860144623f7%3A0x682d1b88032024d!2sEscuela%20Villas%20Juan%20Pablo%20Ac!5e0!3m2!1ses!2smx!4v1588022196525!5m2!1ses!2smx"
              allowFullScreen
            ></iframe>
          </div>

          <div className="title_white_sm mt-5">
            <a className="mr-2" href="https://www.twitter.com">
              <FontAwesomeIcon size="lg" className="text-light" icon="home" />
            </a>
            San Isidro Castillotla, 72498 Puebla, Pue.
          </div>

          <div className="title_white_sm">
            <a className="mr-2" href="https://www.twitter.com">
              <FontAwesomeIcon
                size="lg"
                className="text-light"
                icon="envelope"
              />
            </a>
            contacto@villasjuanpablo.mx
          </div>
          <div className="title_white_sm">
            <a className="mr-2" href="https://www.twitter.com">
              <FontAwesomeIcon size="lg" className="text-light" icon="phone" />
            </a>
            222-241-1262
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Map
