import React, { useState } from "react"
import { Container, Row, Button, Jumbotron } from "reactstrap"

import { SlideMenu } from "../../../components/elements"

import donation_icon from "../../../images/logo/donacion_icon.svg"

import "../../../styles/donations.css"

function Donations() {
  const [slideMenu, setSlideMenu] = useState(false)
  const toggleSlider = () => {
    setSlideMenu(!slideMenu)
  }

  return (
    <>
      <SlideMenu toggle={toggleSlider} slideMenu={slideMenu} />
      <Jumbotron className="jumb_marg" fluid>
        <Container className="bg_img" fluid>
          <Row>
            <p className="text_white_bg">
              No podemos construir este futuro entre pocas personas pero{" "}
              <font color="black">con tu ayuda</font> podemos hacerlo.
            </p>
          </Row>

          <Row>
            <p className="text_white_bg_right">
              <img
                className="don_icon"
                alt={"donation_icon"}
                src={donation_icon}
              />
              Donaciones
            </p>
          </Row>

          <Row>
            <Button
              className="butn_blue_right text_mrgn"
              onClick={toggleSlider}
            >
              Donar
            </Button>
          </Row>

          <Row>
            <div className="text_white_sm_right">
              Una donación ocasional, por pequeña que sea, puede marcar un antes
              y un después en la vida de una persona.
            </div>
          </Row>
        </Container>
      </Jumbotron>
    </>
  )
}
export default Donations
